import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Revival
			</title>
			<meta name={"description"} content={"Де кожна класика має свою історію!"} />
			<meta property={"og:title"} content={"Про нас | Revival"} />
			<meta property={"og:description"} content={"Де кожна класика має свою історію!"} />
			<meta property={"og:image"} content={"https://crestovel.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://crestovel.com/img/4422483.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://crestovel.com/img/4422483.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://crestovel.com/img/4422483.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://crestovel.com/img/4422483.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://crestovel.com/img/4422483.png"} />
			<meta name={"msapplication-TileImage"} content={"https://crestovel.com/img/4422483.png"} />
		</Helmet>
		<Components.Header />
		<Section background="--color-primary" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text
							as="h4"
							margin="12px 0"
							font="--base"
							color="--grey"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							Про нас
						</Text>
						<Text as="h2" margin="12px 0" font="--headline2" md-font="--headline3">
						Де кожна класика має свою історію!
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://crestovel.com/img/3.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--lead"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Наш шлях у Classic Auto Revival розпочався зі спільної мрії: вдихнути нове життя в автомобілі, які сформували епоху. Наш заклад перетворився з пристрасного хобі на професійний притулок, де відроджуються класичні автомобілі. Кожен автомобіль, який заїжджає до нас в майстерню - це більше, ніж просто метал і механіка, це частинка історії. Ми прагнемо зберегти цілісність і дух кожного класичного автомобіля, щоб вони продовжували творити історію на дорогах.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://crestovel.com/img/4.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Що нас відрізняє
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					• Індивідуальна реставрація: Кожен автомобіль унікальний, як і наш підхід до його реставрації, налаштований таким чином, щоб підкреслити його історичну цінність.

					<br />
					<br />
					• Увага до деталей: Від оздоблення салону до відновлення двигуна - кожна деталь ретельно виготовляється відповідно до оригінальних специфікацій.
					<br />
					<br />
					• Передові технології: Ми поєднуємо традиційні методи з сучасними технологіями, щоб досягти найкращих результатів без шкоди для автентичного шарму класики.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 120px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-10">
			<Text
				margin="0px 0px 20px 0px"
				letter-spacing="2px"
				color="#cd9f55"
				font="normal 500 20px/1.5 --fontFamily-sansVerdana"
				border-color="#dca654"
			>
				Приєднуйтесь до нашої спадщини
			</Text>
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box min-width="100px" min-height="100px" lg-width="100%">
					<Text margin="0 0px 0px 0px" font="normal 600 26px/1.2 --fontFamily-serifGeorgia" color="#050c32" sm-font="normal 600 30px/1.2 --fontFamily-serifGeorgia">
					Приєднуйтесь до Classic Auto Revival і станьте частиною безперервної спадщини. Тут ваш класичний автомобіль не просто обслуговується - він отримує історію, яка продовжує розгортатися. Дозвольте нам допомогти вам створити подорож, гідну спадщини вашого автомобіля. Разом ми подбаємо про те, щоб ваш класичний автомобіль залишався не просто засобом пересування, а свідченням непідвладної часу краси та інженерної майстерності.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});